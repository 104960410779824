
import { defineComponent } from "@vue/runtime-core";

  function makeURL(query: Record<string, any>): string {
    let url = "?";
    let queries = [];
    for (let [key, value] of Object.entries(query)) {
      if (value) {
        let encodedValue = encodeURIComponent(value);
        queries.push(`${key}=${value}`);
      }
    }
    url += queries.join("&");
    return url;
  }

  export default defineComponent({
    data() {
      return {
        show: 6,
        order: 'latest',
      }
    },
    methods: {
      nextPage() {
        if (this.page < this.pageCount - 1) {
          let newPage = this.page + 1;
          let query = Object.assign({}, this.$route.query);
          if (Number(query['page']) == 0) {
            delete query['page'];
          } else {
            query['page'] = String(newPage);
          }
          let url = makeURL(query);
          this.$router.replace(url);
        }
      },
      prevPage() {
        if (this.page > 0) {
          let newPage = this.page - 1;
          let query = Object.assign({}, this.$route.query);
          if (Number(query.page) == 0) {
            delete query['page'];
          } else {
            query['page'] = String(newPage);
          }
          let url = makeURL(query);
          this.$router.replace(url);
        }
      }
    },
    computed: {
      listClass() {
        var cls = ["column-row"];
        if (this.visibleStoryCount > 6) {
          cls.push("column-row-1-2");
        } else {
          cls.push("column-row-1");
        }
        return cls;
      },
      style() {
        let style: Record<string, any> = [];
        style['margin-bottom'] = '80px';
        const height = 47 + this.show * 123;
        style['height'] = `${height}px`;
        return style;
      },
      visibleStories() {
        let p = this.page;
        let s = this.show;
        return this.orderedStories.slice(p * s, (p + 1) * s);
      },
      visibleStoryCount() {
        return this.visibleStories.length;
      },
      orderedStories() {
        if (this.order == 'latest') {
          return this.stories;
        } else {
          let copy = this.stories.slice();
          copy.reverse();
          return copy;
        }
      },
      visiblePage() {
        return this.page + 1;
      },
      storyCount() {
        return this.stories.length;
      },
      pageCount() {
        return Math.ceil(this.storyCount / this.show);
      },
      page() {
        const selectedPageStr = this.$route.query.page;
        if (selectedPageStr) {
          const selectedPageNum = Number(selectedPageStr);
          if (selectedPageNum >= 0 && selectedPageNum < this.pageCount) {
            return selectedPageNum;
          }
        }
        return 0;
      }
    },
    props: ['stories', 'initial-page']
  });

<template>
  <!-- rest of the news -->
  <div id="StoryListPanel">
    <div :class="listClass" :style="style">
      <div class="column column-large first last">
        <div class="column-top"></div>
        <div class="column-content">
          <div class="negative-row">

            <p class="column-navigation">
              <a id="HyperLinkLeft" class="play-left update-url" @click="prevPage">«</a>
              {{visiblePage}}&nbsp;of&nbsp;{{pageCount}}
              <a id="HyperLinkRight" class="play-right update-url" @click="nextPage">»</a>
              <label for="show">Show:</label>
              <select id="show" name="show" class="update-url" v-model="show">
                <option value="3">3</option>
                <option value="6">6</option>
                <option value="9">9</option>
              </select>
              <label for="order">Order:</label>
              <select id="order" name="order" class="update-url" v-model="order">
                <option value="latest">Most Recent</option>
                <option value="oldest">Oldest</option>
              </select>
            </p>

            <div class="t-list" id="GeneralNewsList">

              <div v-for="story in visibleStories" class="t-row" style="height: 113px;" :key="story.id">
                <b><i></i></b><i><b></b></i><em></em>
                <div class="t-cell t-first cell-image">
                  <router-link :to="story.href" class="rounded">
                    <img style="width: 133px; height: 100px;" :src="story.image" class="rounded" :alt="story.title">
                  </router-link>
                </div>
                <div class="t-cell cell-description">
                  <h4><router-link :to="story.href">{{story.title}}</router-link></h4>
                  <p>{{story.intro}}</p>
                  <p class="news-network-generalnewsdate small">
                    Posted {{story.date}}
                    <span v-if="story.category">
                      -&nbsp;<router-link :to="story.category.href">{{story.category.title}}</router-link>
                    </span>
                    by {{story.author}}
                  </p>
                </div>
                <div class="t-cell cell-more">
                  <router-link :to="story.href" class="linkArrow">Read More</router-link>
                </div>
              </div>

            </div>
            <p class="column-navigation column-navigation-right">    ­
              <a id="HyperLinkLeftBottom" class="play-left update-url" @click="prevPage">«</a>
              {{visiblePage}}&nbsp;of&nbsp;{{pageCount}}
              <a id="HyperLinkRightBottom" class="play-right update-url" @click="nextPage">»</a>
            </p>
            <div class="clear"></div>
            <!-- clear right navigation //-->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

  function makeURL(query: Record<string, any>): string {
    let url = "?";
    let queries = [];
    for (let [key, value] of Object.entries(query)) {
      if (value) {
        let encodedValue = encodeURIComponent(value);
        queries.push(`${key}=${value}`);
      }
    }
    url += queries.join("&");
    return url;
  }

  export default defineComponent({
    data() {
      return {
        show: 6,
        order: 'latest',
      }
    },
    methods: {
      nextPage() {
        if (this.page < this.pageCount - 1) {
          let newPage = this.page + 1;
          let query = Object.assign({}, this.$route.query);
          if (Number(query['page']) == 0) {
            delete query['page'];
          } else {
            query['page'] = String(newPage);
          }
          let url = makeURL(query);
          this.$router.replace(url);
        }
      },
      prevPage() {
        if (this.page > 0) {
          let newPage = this.page - 1;
          let query = Object.assign({}, this.$route.query);
          if (Number(query.page) == 0) {
            delete query['page'];
          } else {
            query['page'] = String(newPage);
          }
          let url = makeURL(query);
          this.$router.replace(url);
        }
      }
    },
    computed: {
      listClass() {
        var cls = ["column-row"];
        if (this.visibleStoryCount > 6) {
          cls.push("column-row-1-2");
        } else {
          cls.push("column-row-1");
        }
        return cls;
      },
      style() {
        let style: Record<string, any> = [];
        style['margin-bottom'] = '80px';
        const height = 47 + this.show * 123;
        style['height'] = `${height}px`;
        return style;
      },
      visibleStories() {
        let p = this.page;
        let s = this.show;
        return this.orderedStories.slice(p * s, (p + 1) * s);
      },
      visibleStoryCount() {
        return this.visibleStories.length;
      },
      orderedStories() {
        if (this.order == 'latest') {
          return this.stories;
        } else {
          let copy = this.stories.slice();
          copy.reverse();
          return copy;
        }
      },
      visiblePage() {
        return this.page + 1;
      },
      storyCount() {
        return this.stories.length;
      },
      pageCount() {
        return Math.ceil(this.storyCount / this.show);
      },
      page() {
        const selectedPageStr = this.$route.query.page;
        if (selectedPageStr) {
          const selectedPageNum = Number(selectedPageStr);
          if (selectedPageNum >= 0 && selectedPageNum < this.pageCount) {
            return selectedPageNum;
          }
        }
        return 0;
      }
    },
    props: ['stories', 'initial-page']
  });
</script>

<style lang="scss">
  #GeneralNewsList div.cell-description {
    width: 410px; // 390px
    position: relative;
  }

  #GeneralNewsList div.cell-image img {
    width: 390px;
    position: relative;
    background-color: #000;
    display: block;
  }

  #GeneralNewsList .news-network-generalnewsdate {
    position: absolute;
    left: 0px;
    bottom: 12px;
  }
</style>
